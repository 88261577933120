<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('api-endpoint-configurations.list')"  :to="'/settings/api-endpoint-configurations/'+provider_id+'/create'"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('api_configurations.new_api_endpoint_configurations') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="service_provider_id">{{$t('api_configurations.service_provider')}}</label>
                            <select name="" id="service_provider_id" v-model="filters.service_provider_id" class="custom-select">
                                <option v-for="row in service_provider_list" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="protocol_id">{{$t('api_configurations.protocol')}}</label>
                            <select name="" id="protocol_id" v-model="filters.protocol_id" class="custom-select">
                                <option v-for="row in protocol_list" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="slug">{{$t('api_configurations.slug')}}</label>
                            <input v-model="filters.slug" type="text" id="slug" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox  v-if="$can('api-configurations.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox  v-else :disabled="true" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('api-endpoint-configurations.update')"  @click="editItem(props.row.service_provider_id,props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('api-endpoint-configurations.delete')"  @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        components: {},
        data() {
            return {
                mainRoute: 'settings/api-endpoint-configurations',
                subMainRoute: 'settings/api-endpoint-configuration',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                provider_id: this.$route.params.provider_id ? this.$route.params.provider_id : '',
                filters: {
                    service_provider_id: this.$route.params.provider_id ? this.$route.params.provider_id : '',
                    protocol_id: '',
                    slug: '',
                    is_active: '',
                },

                protocol_list: [
                    {id: 1, name: 'http'},
                    {id: 2, name: 'https'},
                ],

                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                service_provider_list: [],

                columns: ['slug', 'service_provider_name', 'protocol_name', 'user_name', 'status', 'actions'],


                disabledButton: false,
                validation: [],
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        slug: that.$t('api_configurations.slug'),
                        service_provider_name: that.$t('api_configurations.service_provider'),
                        protocol_name: that.$t('api_configurations.protocol'),
                        user_name: that.$t('api_configurations.user'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['created_at'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params})

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Api_configurations"), route:'/settings/api-configurations'}, {title: this.$t("MENU.Api_endpoint_configurations")}]);
            this.getProviders();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.protocol_id = '';
                this.filters.slug = '';
                this.filters.is_active = '';

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(provider_id, item) {
                this.$router.push({name: 'apiEndpointConfigurations.edit', params: {provider_id: provider_id, id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            getProviders() {
                ApiService.get(this.mainRouteDependency + "/services-providers").then((response) => {
                    this.service_provider_list = response.data.data;
                });
            },

        },
    };
</script>
